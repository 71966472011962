<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Edit Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Edit Password",
      sortName : false,
      recent : "",
      recentType : "password",
      newPass : "",
      newPassType : "password",
      confirmPass : "",
      confirmPassType : "password",
    }
  },
  computed: {
    firstname(){
        return this.$store.getters['user/firstName'];
    },
    lastname(){
        return this.$store.getters['user/lastName'];
    },
    email(){
        return this.$store.getters['user/email'];
    },
    loading(){
        return this.$store.getters['user/loading'];
    },
  },
  mounted(){
    // this.$ga.page('/editpassword')
    this.$ga.event('edit-pw-page', 'actionx', 'labelx', 1)
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("storage/SET_SHOWBREADCUMB",false);
    this.$store.commit("storage/SET_SUBTITLE","Edit Password");
  },
  methods:{
      submit(){
          if(this.newPass != this.confirmPass){
            this.$store.commit("error/SET_MESSAGE","Confirm Password does not match");
            this.$store.commit("error/SET_ERROR",true);
          }else{
            this.$store.dispatch("user/editPassword",
            {
                firstname: this.firstname,
                lastname: this.lastname,
                email: this.email,
                password: this.newPass
            });
          }
      }
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex flex-column align-items-center justify-content-center">
            <span class="mt-4"></span><br>
            <img src="@/assets/images/shield.png" alt class="img-fit mb-4" />
            <div class="form mx-2">
                <b-form-group id="input-group-4" label="Recent Password"  class="py-2 mt-2" label-class="label-input float-left"  label-for="input-2">
                    <b-input-group>
                        <b-form-input
                            id="input-4"
                            v-model="recent"
                            :type="recentType"
                            class="login-input-style"
                            placeholder="Recent Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button  @click="()=>{recentType = (recentType == 'password') ?  'text' : 'password' }"  class="btn-on-input"><i class="dripicons-preview"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group id="newPass" label="New Password"  class="py-2 mt-2" label-class="label-input float-left"  >
                    <b-input-group>
                        <b-form-input
                            id="newPassP"
                            v-model="newPass"
                            :type="newPassType"
                            class="login-input-style"
                            placeholder="New Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button   @click="()=>{newPassType = (newPassType == 'password') ?  'text' : 'password' }"  class="btn-on-input"><i class="dripicons-preview"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group id="confirmPass" label="Confirm Password"  class="py-2 mt-2" label-class="label-input float-left"  >
                    <b-input-group>
                        <b-form-input
                            id="confirmPassP"
                            v-model="confirmPass"
                            :type="confirmPassType"
                            class="login-input-style"
                            placeholder="Confirm Password"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-button   @click="()=>{confirmPassType = (confirmPassType == 'password') ?  'text' : 'password' }"  class="btn-on-input"><i class="dripicons-preview"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
              <div class="mt-2 pt-2 d-flex justify-content-between align-items-center">
                   <router-link tag="a" to="/" class="font-weight-medium text-blue bold-600 label-input">Cancel</router-link>
                   <button class="btn base-btn bg-grey" @click="submit">
                     <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                     <span>Edit Password</span>
                    </button>
              </div>
            </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
  </Layout>
</template>
